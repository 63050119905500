import React from "react";
import styles from "./FormField.module.css";

import PhoneInputWithCountrySelect from "react-phone-number-input";
import 'react-phone-number-input/style.css'

/**
 * @param {{
 * type: "text" | "password" | "dropdown" | "textarea",
 * fieldIcon: string,
 * placeholder?: string | undefined,
 * name: string,
 * value: any,
 * setter: (value: any) => void,
 * misc?: any,
 * dropdownValues: {name: string, value: string|number}[]}
 * info: string,
 * isReadOnly?: boolean
 * }} props 
 * @returns 
 */
const FormField = ({
	type,
	fieldIcon,
	placeholder,
	name,
	value,
	setter,
	misc,
	dropdownValues,
	info,
	isReadOnly = false,
}) => {
	const renderFormField = () => {
		if (["text", "password"].includes(type)) {
			return (
				<>
					<div className={`${styles.formField}`}>
						<img
							src={fieldIcon}
							className={`${styles.formFieldIcon}`}
							alt={name}
						/>
						<input
							type={type}
							placeholder={placeholder}
							value={value[name]}
							onChange={(e) =>
								setter({
									key: name,
									value: e.target.value,
									memberNo: misc?.memberNo,
								})
							}
							readOnly={isReadOnly && "readonly"}
						/>
					</div>
					{info && <p className={`${styles.info}`}>{info}</p>}
				</>
			);
		} else if (type === "dropdown") {
			return (
				<>
					<div className={`${styles.formField} ${styles.selectDiv}`}>
						<img
							src={fieldIcon}
							className={`${styles.formFieldIcon}`}
							alt={name}
						/>
						<select
							value={value[name]}
							onChange={(e) =>
								setter({
									key: name,
									value: e.target.value,
									memberNo: misc?.memberNo,
								})
							}
						>
							<option value="" disabled>
								{placeholder}
							</option>
							{dropdownValues.map((item, index) => {
								return (
									<option key={index} value={item.value}>
										{item.name}
									</option>
								);
							})}
						</select>
					</div>
					{info && <p className={`${styles.info}`}>{info}</p>}
				</>
			);
		} else if (type === "textarea") {
			return (
				<>
					<div className={`${styles.formField}`}>
						<img
							src={fieldIcon}
							className={`${styles.formFieldIcon}`}
							alt={name}
						/>
						<textarea
							placeholder={placeholder}
							className={`${styles.textarea}`}
							value={value[name]}
							onChange={(e) =>
								setter({
									key: name,
									value: e.target.value,
								})
							}
						/>
					</div>
					{info && <p className={`${styles.info}`}>{info}</p>}
				</>
			);
		} else if (type === "phone") {
			return (
				<>
					{info && <p className={`${styles.info}`}>{info}</p>}
					<div className={`${styles.formField}`}>
						<img
							src={fieldIcon}
							className={`${styles.formFieldIcon}`}
							alt={name}
						/>
						<PhoneInputWithCountrySelect
							defaultCountry="IN"
							countrySelectProps={{ unicodeFlags: true }}
							countryCallingCodeEditable={false}
							international={true}
							placeholder={placeholder}
							className={`${styles.phone}`}
							onChange={(e) => {
								setter({
									key: name,
									value: e,
									memberNo: misc?.memberNo,
								})
							}
							}
							readOnly={isReadOnly}
						/>
					</div>
				</>
			);
		} else {
			return null;
		}
	};

	return <>{renderFormField()}</>;
}

export default FormField;
