import React from 'react';
import styles from './RegistrationInfoPane.module.css';

const RegistrationInfoPane = () => {
   return (
      <section className={styles.wrapper}>
         <div className={styles.section}>
            <div className={styles.sectionHeading}>WORKSHOPS - Rs.200 for each workshop.</div>
            <ul className={styles.sectionList}>
               <li className={styles.sectionListItem}>
                  Blockchain Unleashed: Cryptography, dApps and Smart contracts <b>(Hands-on)</b>
               </li>
            </ul>
            <ul className={styles.sectionList}>
               <li className={styles.sectionListItem}>
                  Trends in Transmission: Automobile transmission systems and sensors - <b>(Hands-on)</b>
               </li>
            </ul>
         </div>
         <div className={styles.section}>
            <div className={styles.sectionHeading}>
               EVENTS - <b>Free to participate!</b>
            </div>
            <ul className={styles.sectionList}>
               <li className={styles.sectionListItem}>
                  Kryptics - Kryptics is a game based on engineering themes, principles, technical words, and universally acknowledged facts designed to test your
                  vocabulary, creativity, and quick thinking as you link one clue to another. There will be two rounds. Candidates will be shortlisted from round 1 and go
                  on to round 2.
               </li>
            </ul>
            <ul className={styles.sectionList}>
               <li className={styles.sectionListItem}>
                  Code Clash Royale - It is an event based on the coding knowledge of the participants. 1 vs. 1 battle where two competitors can expose their knowledge
                  and challenge their opponents with complex questions in coding. The event necessitates coding knowledge and problem-solving ideas. There will be two
                  rounds. Candidates will be shortlisted from round 1 and go on to round 2.
               </li>
            </ul>
         </div>
         <a href="/upi.jpg" target='__blank'>
            <button className={styles.infoDocButton}>
               <div className={styles.infoDocName}>
                  <span>&nbsp;Show UPI QR for Payment</span>
               </div>
            </button>
         </a>
      </section>
   );
};

export default RegistrationInfoPane;

