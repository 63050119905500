export const KARNIVAL = [
	{
		src: ['KarnivalImages/drdo.png',],
		name: 'DRDO Exhibition',
		venue: 'Infront of Chemistry Dept.'
	},
	{
		src: ['KarnivalImages/isro.png',],
		name: 'ISRO Exhibition',
		venue: 'Infront of Chemistry Dept.'

	},
	{
		src: ['KarnivalImages/nft.png',],
		name: 'NFT Exhibition',
		venue: 'Knowledge Park 210,211'
	},
	
	
	{
		src: ['KarnivalImages/motion.png',],
		name: 'Motion Controlled Devices',
		venue: 'Knowledge Park 210,211'
	},
	{
		src: ['KarnivalImages/vr.png',],
		name: 'VR Experience',
		venue: 'Science and Humanities Parking'
	},
	{
		src: ['KarnivalImages/fiesta.png',],
		name: 'K!Fiesta',
		venue: 'Blue Shed'
	},
];