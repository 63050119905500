import React from "react";
import styles from "./KAwards.module.css";
import { useEffect } from "react";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";

const KAwardsnominee = () => {
    ScrollToTop();
  const gformUrl =
    "https://docs.google.com/forms/d/e/1FAIpQLSdfTepu8LqH_YwobQ980wCXYavM1b1FiS4XgbQZTZyg9FBuqQ/viewform?embedded=true";
  return (
    
    <div className={styles.content}>
    <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className={styles.ideas_title}>K! Award Nominee</div>
      <div>
      <iframe className="iframe-container"
        src={gformUrl}
        frameborder="0"
        marginheight="10"
        marginwidth="0"
        scrolling="yes"
      >
        Loading…
      </iframe>
      </div>
{/* 
      <iframe
        src={gformUrl}
        width="370"
        height="2152"
        frameborder="0"
        marginheight="10"
        marginwidth="0"
        scrolling="yes"
      >
        Loading…
      </iframe> */}


      <br />
      <br />
      <br />
    </div>
  );
};

export default KAwardsnominee;
