import React from 'react'
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';
import Form from './Form';

function Contact() {
  ScrollToTop();
  return (
    <div>
      <Form />
    </div>
  )
}

export default Contact