import React from 'react';
import styles from './MediaLink.module.css'

const MediaLink = ({ content, link, type }) => {
	let prefix;
	switch (type) {
		case 'phone': prefix = 'tel:';
			break;
		case 'mail': prefix = 'mailto:';
			break;
		case 'web': prefix = 'https://';
			break;
	}
	return (
		<p className={styles.wrapper}>
			<a className={styles.plainLink} href={prefix + link} target='_blank'>
				{content ? content + ': ' + link : link}
			</a>
		</p>
	);
}

export default MediaLink;