import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, EffectFade } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import styles from "./SwiperComp.module.css";
import SimpleLoader from "../SimpleLoader/SimpleLoader";

function SwiperComp({ images }) {
    const [imagesLoaded, setimagesLoaded] = useState(false);
    let imageCounter = useRef(0);

    const onImageLoad = () => {
        imageCounter.current += 1;

        // If all images are loaded, set loader to false
        if (imageCounter.current >= images.length) {
            setimagesLoaded(true);
            imageCounter.current = 0;
        }
    };
    return (
        <div className={`${styles.container}`}>
            {!imagesLoaded && <SimpleLoader />}
            <Swiper
                modules={[Navigation, EffectFade]}
                navigation
                speed={800}
                slidesPerView={1}
                loop
                autoplay
                className={styles.myswiper}
                style={imagesLoaded ? {} : { display: "none" }}
            >
                {images.map((img) => {
                    return (
                        <SwiperSlide className={`${styles.swiperslide}`}>
                            <img src={img} alt={""} onLoad={onImageLoad} />
                        </SwiperSlide>
                    )
                })}
            </Swiper>
        </div>
    )
}

export default SwiperComp