import { axiosInstance } from "./axiosInstance";

const url_registerUser = "api/user/register";

export const apiRegisterUser = async (data) => {
  try {
    const response = await axiosInstance.post(`${url_registerUser}`, data   );
    return response;
  } catch (error) {
    return error.response;
  }
};