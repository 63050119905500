import React, { useRef, useState } from "react";
import styles from "./Form.module.css";
import ReCaptcha from "react-google-recaptcha";
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaLinkedinIn,
} from "react-icons/fa";
import {
  FiMail
} from "react-icons/fi"
import {
  AiOutlineArrowDown
} from "react-icons/ai"
import { showErrorToastNotification, showSuccessToastNotification, showWarnToastNotification } from "../../components/ToastNotification/ToastNotification";
import { validateContactForm } from "../../validators/contactForm";
import SimpleLoader from "../../components/SimpleLoader/SimpleLoader";
import { apiSendQuery } from "../../api/sendQuery";

const Form = () => {
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [message, setmessage] = useState("");

  const [loading, setLoading] = useState(false);

  let recaptchaRef = useRef();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (recaptchaRef.current.getValue() === "") {
      showErrorToastNotification("Fill the ReCAPTCHA first!")
      return;
    }

    const validationResult = validateContactForm(name, email, phone, message);

    if (!validationResult.status) {
      showErrorToastNotification(validationResult.message);
      return;
    }

    console.log(name);
    console.log(email);
    console.log(phone);
    console.log(message);

    setLoading(true);
    const resp = await apiSendQuery({
      name: name,
      email: email,
      phone: phone,
      message: message,
      recaptcha: recaptchaRef.current.getValue()
    });
    recaptchaRef.current.reset();
    setLoading(false);

    if (resp === undefined) {
      showErrorToastNotification("Oops! Something went wrong... Please try again later.");
    } else {
      if (resp.status === 200) {
        showSuccessToastNotification(resp.data.message);
        setname("");
        setemail("");
        setphone("");
        setmessage("");
      } else if (resp.status >= 400 && resp.status < 500) {
        showErrorToastNotification(resp.data.message);
      } else if (resp.status >= 500 && resp.status < 600) {
        showWarnToastNotification("Server Error. Try again after sometime!");
      }
    }
  };

  const [hover, setIsHover] = useState(false);
  const [hover1, setIsHover1] = useState(false);
  const [hover2, setIsHover2] = useState(false);
  const [hover3, setIsHover3] = useState(false);
  const [hover4, setIsHover4] = useState(false);

  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const handleMouseEnter1 = () => {
    setIsHover1(true);
  };
  const handleMouseLeave1 = () => {
    setIsHover1(false);
  };
  const handleMouseEnter2 = () => {
    setIsHover2(true);
  };
  const handleMouseLeave2 = () => {
    setIsHover2(false);
  };
  const handleMouseEnter3 = () => {
    setIsHover3(true);
  };
  const handleMouseLeave3 = () => {
    setIsHover3(false);
  };
  const handleMouseEnter4 = () => {
    setIsHover4(true);
  };
  const handleMouseLeave4 = () => {
    setIsHover4(false);
  };


  const mystyle = {
    icon: {
      fontSize: "40px",
      lineHeight: "60px",
      transition: ".1s",
      color: "black",
      // color: isHover? "black" : "white",
      width: "100%",
    },
    icon1: {
      fontSize: "30px",
      lineHeight: "60px",
      transition: ".1s",
      color: "white",
      width: "100%",
    },
  };

  return (
    <div className={styles.container}>
      <div className={styles.text}>
        <h2 className={styles.textTitle}>Contact Us</h2>
        <h3 style={{marginBottom: "10px"}}>Have any questions or comments&nbsp;?</h3>
        <p>
          Feel free to contact us for any issues or clarifications. We would be
          happy to answer your questions and clarify your doubts.
        </p>
        <div className={styles.contactDetails}>
          <div>
            <h5 className={styles.textName}>Vikashini T</h5>
            <p className={styles.textNumber}>+91 9080469446</p>
          </div>
          <div>
            <h5 className={styles.textName}>Vikram D</h5>
            <p className={styles.textNumber}>+91 8825786603</p>
          </div>
        </div>
        <div className={styles.contactDetails}>
          <div>
            <h5 className={styles.textName}>E-mail:</h5>
            <p className={styles.textNumber}>xceed@cegtechforum.in</p>
          </div>
        </div>

        <div className={styles.social_menu}>
          <ul className={styles.social_menu_ul}>
            <li
              className={styles.social_menu_ul_li}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <a
                href="https://instagram.com/ceg_tech_forum/"
                target="blank"
                className={styles.social_menu_ul_li_a}
              >
                <FaInstagram
                  id="insta"
                  style={hover ? mystyle.icon1 : mystyle.icon}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                />
              </a>
            </li>
            <li className={styles.social_menu_ul_li}>
              <a
                href="https://facebook.com/techforum.ceg/"
                target="blank"
                className={styles.social_menu_ul_li_a}
              >
                <FaFacebookF
                  style={hover1 ? mystyle.icon1 : mystyle.icon}
                  onMouseEnter={handleMouseEnter1}
                  onMouseLeave={handleMouseLeave1}
                ></FaFacebookF>
              </a>
            </li>
            <li className={styles.social_menu_ul_li}>
              <a
                href="https://twitter.com/ceg_tech_forum"
                target="blank"
                className={styles.social_menu_ul_li_a}
              >
                <FaTwitter
                  style={hover2 ? mystyle.icon1 : mystyle.icon}
                  onMouseEnter={handleMouseEnter2}
                  onMouseLeave={handleMouseLeave2}
                ></FaTwitter>
              </a>
            </li>
            <li className={styles.social_menu_ul_li}>
              <a
                href="https://linkedin.com/company/ceg-tech-forum/"
                target="blank"
                className={styles.social_menu_ul_li_a}
              >
                <FaLinkedinIn
                  style={hover3 ? mystyle.icon1 : mystyle.icon}
                  onMouseEnter={handleMouseEnter3}
                  onMouseLeave={handleMouseLeave3}
                ></FaLinkedinIn>
              </a>
            </li>
            <li className={styles.social_menu_ul_li}>
              <a
                href="mailto:xceed@cegtechforum.in"
                target="blank"
                className={styles.social_menu_ul_li_a}
              >
                <FiMail style={hover4 ? mystyle.icon1 : mystyle.icon}
                  onMouseEnter={handleMouseEnter4}
                  onMouseLeave={handleMouseLeave4}
                >
                </FiMail>
              </a>
            </li>
          </ul>
          <div>
            <p>About CTF <br />
              <a href="https://cegtechforum.in/" className={styles.CTFLink}><img src="ctf_logo_dark.png" ></img></a>
            </p>
          </div>
        </div>
        <div className={styles.downBtncontainer}><div className={styles.bg}></div>
          <div className={styles.downButton}><a href="#contactForm" ><AiOutlineArrowDown style={{ fontSize: "2rem" }} /></a></div>
        </div>
      </div>
      <div className={styles.form__container} id="contactForm">
        <form onSubmit={(e) => handleSubmit(e)} className={styles.form_outline} >
          <label className={styles.label} htmlFor="Name">
            Name
          </label>
          <input
            className={styles.input}
            type="text"
            id="full_name"
            required
            name="fname"
            value={name}
            onChange={(e) => setname(e.target.value)}
          />
          <label className={styles.label} htmlFor="email">
            Email
          </label>
          <input
            className={styles.input}
            type="email"
            id="email"
            required
            name="email"
            value={email}
            onChange={(e) => setemail(e.target.value)}
          />
          <label className={styles.label} htmlFor="number">
            Phone Number
          </label>
          <input
            className={styles.input}
            type="number"
            id="number"
            name="phonenum"
            value={phone}
            onChange={(e) => setphone(e.target.value)}
          />
          <label className={styles.label} htmlFor="msg">
            Your Message
          </label>
          <textarea
            className={styles.textarea}
            name="message"
            id="msg"
            required
            value={message}
            onChange={(e) => setmessage(e.target.value)}
          ></textarea>
          <ReCaptcha
            sitekey={process.env.REACT_APP_CAPTCHA_KEY}
            style={{ display: loading && "none" }}
            ref={recaptchaRef} />
          {loading && <SimpleLoader text="Sending your query..." />}
          <button
            style={{ display: loading && "none" }}
            disabled={loading}
            id={styles.btn__send}>
            Send
          </button>
        </form>
      </div>
    </div>
  );
};
export default Form;
