import React from 'react'
import styles from './XceedType.module.css'
import { XCEEDINFO } from '../../data/XceedInfoCards'
import ScrollToTop from '../ScrollToTop/ScrollToTop';
import CardComp from '../CardComp/CardComp';
function XceedType(props) {
    ScrollToTop();
    return (
        <div className='xceedInfoTypes'>
            <XceedTypeComponent content={XCEEDINFO.filter((e) => e.name === props.type)[0]} />
        </div>
    )
}

const XceedTypeComponent = ({ content }) => {
    return (
        <div className={`${styles.xceedTypeContainer}`}>
            <h2 className={`${styles.xceedTypeTitle}`}>{content.name}</h2>

            <p className={`${styles.xceedTypeDescription}`}>{content.description}</p>

            <CardComp
                title={content.name}
                images={content.images}
                list={content.stuff ?? []}
            />

            {/* <div className={`${styles.xceedAllYearsContainer}`}>
                {
                    content.years.map((type, index) => {
                        if (index >= 0) {
                            return (
                                <div key={index} className={`${styles.xceedTypeYearWiseContainer}`}>
                                    <div className={`${styles.xceedYearImage}`}>
                                        <img src={type.image}
                                            alt={type.year}
                                            className={`${styles.xceedTypeYearWiseImage}`}
                                        />
                                    </div>

                                    <div className={`${styles.xceedYearContents}`}>
                                        <h2 className={`${styles.xceedTypeYearWisePlace}`}>
                                            {type.place}
                                        </h2>

                                        <h2 className={`${styles.xceedTypeYearWiseYear}`}>
                                            {type.year}
                                        </h2>

                                        <p className={`${styles.xceedTypeYearWiseContents}`}>
                                            {type.contents}
                                        </p>
                                    </div>
                                </div>
                            );
                        } else {
                            return (
                                <div key={index} className={`${styles.xceedTypeYearWiseContainer}`}>
                                    <div className={`${styles.xceedYearContents}`}>
                                        <h2 className={`${styles.xceedTypeYearWisePlace}`}>
                                            {type.place}
                                        </h2>

                                        <h2 className={`${styles.xceedTypeYearWiseYear}`}>
                                            {type.year}
                                        </h2>

                                        <p className={`${styles.xceedTypeYearWiseContents}`}>
                                            {type.contents}
                                        </p>
                                    </div>

                                    <div className={`${styles.xceedYearImage}`}>
                                        <img src={type.image}
                                            alt={type.year}
                                            className={`${styles.xceedTypeYearWiseImage}`}
                                        />
                                    </div>
                                </div>
                            )
                        }
                    })
                }
            </div> */}
        </div>
    );
};

export default XceedType;