import React, { useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import styles from "./Landing.module.css";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
function Landing() {
   ScrollToTop();
   const navigate = useNavigate();
   // const [isHovered, setIsHovered] = useState(false);
   return (
      <>
      <div className={styles.hero}>
        <div className={styles.heroTextContainer}>
          <h1 className={styles.heroText}>Xceed & K!arnival</h1>
          <h2 className={styles.heroSubText}>
            We pave way for challenges which eventually transform to
            opportunities
          </h2>
          <div>
          <Box sx={{ "& button": { m: 2 } }}>
        <div>
          <Button
            variant="contained"
            size="large"
            color="secondary"
            onClick={() => navigate("/registration")}
            endIcon={<RocketLaunchIcon />}
          >
            Apply for XCEED'24!
          </Button>
        </div>
      </Box>
               </div>
        </div>
        {/* <img className={styles.peaks} src={`${process.env.PUBLIC_URL}/layered-peaks.svg`} alt="" /> */}
      </div>
    </>
  );
}

export default Landing;
