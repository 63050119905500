import { axiosInstance } from "./axiosInstance";

const url_sendQuery = "api/user/query";

export const apiSendQuery = async (data) => {
	try {
		const response = await axiosInstance.post(`${url_sendQuery}`, {
			name: data.name,
			email: data.email,
			phone: data.phone,
			message: data.message,
			captcha: data.recaptcha,
		});
		return response;
	} catch (error) {
		return error.response;
	}
};