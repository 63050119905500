export const XCEEDINFO = [
	{
		id: '1',
		name: "National Xceed",
		images:
			Array.from({ length: 4 }, (_, i) => i + 1).map(num => `/XceedImages/techx${num}.jpg`),
		description: "Being a miniature of Kurukshetra, XCEED is aimed at reaching out to the student and corporate communities across the country to bring in quality participation to Kurukshetra. XCEED serves as a campaign vehicle to carry the spectacle of Kurukshetra nationwide. It focuses on conducting intriguing events and workshops by the effusive minds of CTF. The winners at Xceed secure passage to showcase their calibre in the grand finale at Kurukshetra.",
		stuff: [
			"Battle of bots: Being the creator of an amazing robot that meets the problem statement provided is the holy grail of this national robotics competition. This competition has two rounds: preliminary and final. The preliminary round is held at various colleges throughout the country. Finalists are chosen from among those who excel under the most difficult conditions. The final round took place in CEG, and the winner received grand prizes. Over 15 colleges from across India participated in the Battle of Bots 18.",
			"Geekathon: Tech geeks from all over India participate in a 24-hour hackathon in which they pitch their ideas, develop them, and present them to judges with the goal of creating a product for the benefit of the community. Coding enthusiasts from all over the country come together to burn the midnight oil for a 24-hour endurance test. Participants must pitch their ideas and present them to the judging panel. The ultimate goal of this event is to create a product that benefits the community. For this event around 20 colleges across India participated."
		],
	},
	{
		id: '2',
		name: "Managerial Xceed",
		images:
			Array.from({ length: 4 }, (_, i) => i + 1).map(num => `/XceedImages/manx${num}.jpg`),
		description: "Xceed brings you yet another chance to test and train your management capabilities, a quality necessary for any aspiring entrepreneur. This platform offers you a variety of B-skill nurturing events that are created especially to aid a developing mind in exploring the broad and fascinating field of management. Travel into the events to feel renewed and engaged as well as to acquire a broad range of skills.",
	},
	{
		id: '3',
		name: "Corporate Xceed",
		images:
			Array.from({ length: 4 }, (_, i) => i + 1).map(num => `/XceedImages/corpx${num}.jpg`),
		description: "Corporate Xceed is the latest endeavor of the Xceed team to explore uncharted lands, pushing the boundaries of Kurukshetra. This novel pursuit features general, technical, and managerial events being conducted in various corporate pavilions across the city. Kurukshetra is the only tech fest to conduct events among corporate audiences, indicating its excellent corporate relationships and network. It gives both students and corporations an equal opportunity to benefit from each other's knowledge and experience.",
		stuff: [
			"Witquiz: A general quiz designed specifically for corporate intellectuals. These events are held in corporate hubs across the country. It is divided into two stages: preliminary and final. The response to this competition has been overwhelmingly positive.",
			"Webinar: A webinar is an interactive event that consists of a presentation, lecture, or workshop given by a famous person and is broadcast over the internet. XCEED's embrace of technology has sparked panel discussions in a variety of corporate and management settings. Webinar has also used web conferencing to discuss and debate current technological topics.",
		],
	},
	{
		id: '4',
		name: "School Xceed",
		images:
			Array.from({ length: 4 }, (_, i) => i + 1).map(num => `/XceedImages/corpx${num}.jpg`),
		description: "Corporate Xceed is the latest endeavor of the Xceed team to explore uncharted lands, pushing the boundaries of Kurukshetra. This novel pursuit features general, technical, and managerial events being conducted in various corporate pavilions across the city. Kurukshetra is the only tech fest to conduct events among corporate audiences, indicating its excellent corporate relationships and network. It gives both students and corporations an equal opportunity to benefit from each other's knowledge and experience.",
		stuff: [
			"Witquiz: A general quiz designed specifically for corporate intellectuals. These events are held in corporate hubs across the country. It is divided into two stages: preliminary and final. The response to this competition has been overwhelmingly positive.",
			"Webinar: A webinar is an interactive event that consists of a presentation, lecture, or workshop given by a famous person and is broadcast over the internet. XCEED's embrace of technology has sparked panel discussions in a variety of corporate and management settings. Webinar has also used web conferencing to discuss and debate current technological topics.",
		],
	}
]