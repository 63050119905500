import React from 'react'
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';
import XceedInfo from '../../components/XceedInfo/XceedInfo';
import styles from './Xceed.module.css'

function Xceed() {
  ScrollToTop();
  return (
    <div className={`${styles.xceedInfo}`}>
      <XceedInfo />
    </div>
  );
}


export default Xceed