import React, { useEffect } from 'react'
import { useNavigate } from 'react-router';
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';
import styles from "./PageNotFound.module.css"

function PageNotFound() {
  ScrollToTop();
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate('/');
    }, 4000);
  }, [])

  return (
    <div className={styles.wrapper_404}>
      <h1>Whoa! Looks like you're lost. Redirecting you back home...</h1>
      <img className={styles.img_404} src='/404.svg' alt='404 Not Found' />
    </div>
  )
}

export default PageNotFound