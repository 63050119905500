import React from 'react';
import styles from './Karnival.module.css';

import { Zoom } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';
import { KARNIVAL } from '../../data/Karnival';

function Karnival() {
   ScrollToTop();

   // /** @type {import('react-slideshow-image').ZoomProps}*/
   // const zoomOutProperties = {
   //    duration: 5000,
   //    transitionDuration: 500,
   //    infinite: true,
   //    indicators: true,
   //    scale: 0.4,
   //    arrows: true
   // };

   return (
      <div>
         <h1 className={`${styles.title}`}>K!arnival</h1>
         <h4 className={`${styles.desc}`}>
            K!arnival is the exhilarating face of Kurukshetra. Since its inception in 2011, it has exposed K! to not only geeky engineers but also creative artists. K!arnival showcases discoveries, helping its viewers understand the strategies and concepts behind them. It also conducts workshops on non-technical yet useful topics.
         </h4>
         <br />
         <br />
         <div className={`${styles.karnivalMain}`}>
            {KARNIVAL.map((val, idx) => {
               return (
                  <div key={idx} className={`${styles.karnivalTypeCard}`}>
                     {/* <Zoom {...zoomOutProperties} className="slide-container">
                        {val.src.map((each, index) => (
                           <img key={index} alt={val.year} src={each} className={`${styles.karnivalTypeYearWiseImage}`} />
                        ))}
                     </Zoom> */}
                     <img alt={val.year} src={val.src[0]} className={`${styles.karnivalTypeYearWiseImage}`} />
                     <br></br>
                     <h2 className={`${styles.karnivalTypeTitle}`}>{val.name}</h2>
                     <h3 
                     style={{
                        paddingBottom: "25px",
                        fontSize: "15px"
                     }}
                     >  </h3>
                     {/* <p className={`${styles.karnivalTypeDescription}`}>{val.desc}</p> */}
                  </div>
               )
            })}
         </div>
      </div>
   );
}

export default Karnival;