export const KAWARDS = [
	{
		images:
			Array.from({ length: 9 }, (_, i) => i + 1).map(num => `/KawardsImages/${num}.jpg`)
		,
		list: [
			
			  {
				 
				subItems: [
					'Dr R Malathy, Dr SRR Senthil Kumar, N Karuppasamy and K Dhinesh Babu – "Concrete with shredded plastic"',
				  'Balachandar and Balakumar – "Automated Heat developer"',
				  'Arun Venkataramanan – "Empowering Environmentalist"',
				  'V S Sabu – "Tesla Coil Recreation"',
				  'H. Afsal Mohammad – "Special Eye Glass For Visually Impaired"'
				]
			  },
              
			 {
				subItems: [
                    'Udhaya Kumar - "Time inventor”', 
					'C. Arun Prabhu NG – "The modern minimalist”',
					 'Pranesh, Rupesh, Kavinandhan (Students - Ananya Vidyalaya) – "Best young innovator”', 
					'Aswatha Biju – "Alan grant”, Nishanth and Anand – "The Best Solution”', 
					
				]
			 },
			 {
				subItems:[
                    'Riyasdeen S- "Innovation Enthusiast”',
					'C. Balamurugan- "Creative Genius” ',
					'Abdul Kareem - "Ideal Conservationist”',
					'Dhanush Kumar- "Reforming Revolutionist”', 
					'Sona Engg College Students- "Moral saviours”', 
				
				]
			 },
			 {
				subItems: [
					'Mohammed Gaddafi - "The Exemplar Differentia"',
				  'Saravana Muthu - "Evolution viz Revolution"',
				  'P.A.Sekar - "Expertise of Experience"',
				  'Sean Solano Paul - "Prodigious Wizard"',
				  'Bagya Lakshmi - "Nascent Novelty"',
				 
				]
			},
			{
				subItems:[
					'Shantha Mohan – "The chronicler of CEG" ',
                     'Pothum Ponnu and Kannan – "Exuberant Edifiers” ',
					 'Akashwaran VM – "Budding Boffin”', 
					 'Dr. Soundarajan – "Endeavoured clinician” ' ,
			         'Vaishnavi, Girija, and Sharmila – "Trailblazers in tech” ',
                    
					]
			},
			
			
		

			],
		yearlist : [
		
			'2023',
			'2022',
			'2021',
			'2020',
			'2019'
		]
	}
];
