import React from "react";
import { styled } from '@mui/material/styles';
import SwiperComp from "../SwiperComp/SwiperComp";
import styles from "./CardComp.module.css";
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `none`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&::before': {
      display: 'none',
    },
  }));
  
  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
    //   expandIcon={<ArrowForwardIosSharpIcon color="white" sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      'rgba(205, 209, 228);',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  }));
  
  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
  }));
function Links({ links }) {
  return links.map((link, index) => {
    return (
      <li key={index} className={styles.tag__item}>
        <a href={link.url} target="_blank">
          {link.title}
        </a>
      </li>
    );
  });
}

function CardCompAccordian({ title, images, year, text, links, list }) {
  return (
    <li>
      <section className={styles.dark}>
        <div className={`${styles.container} ${styles.py - 4}`}>
          <div className={`${styles.postcard} ${styles.dark} ${styles.blue}`}>
            <div className={styles.postcard__img_link}>
              <SwiperComp className={styles.postcard__img} images={images} />
            </div>
            <div className={styles.postcard__text}>
              <h1 className={`${styles.postcard__title} ${styles.blue}`}>
                {title}&nbsp;
              </h1>
              {text && <li className={styles.postcard__preview_txt}>{text}</li>}
              <ul className={styles.postcard__preview_ul}>
              <div>
                {list.map((item, idx) => {
                  return (
                    
                      <Accordion square={false}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon backgroundColor="white" color="white"/>}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography>{year[idx]}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                        <div className={styles.cardcompt}>
                          <ul>
          {item.subItems.map((subItem, index) => (
            <li key={index}>{subItem}</li>
          ))}
        </ul>
        </div>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>

                      
                    
                  );
                })
                }
                </div>
              </ul>
              <ul className={styles.postcard__tagbox}>
                {links && <Links links={links} />}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </li>
  );
}

export default CardCompAccordian;
