import React from "react";
import SwiperComp from "../SwiperComp/SwiperComp";
import styles from "./CardComp.module.css";

function Links({ links }) {
    return links.map((link, index) => {
        return (
            <li key={index} className={styles.tag__item}>
                <a href={link.url} target="_blank">
                    {link.title}
                </a>
            </li>
        );
    })
}

function CardComp({ title, images, year, text, links, list }) {
    return (
      <li>
          <section className={styles.dark}>
            <div className={`${styles.container} ${styles.py - 4}`}>
                <div className={`${styles.postcard} ${styles.dark} ${styles.blue}`}>
                    <div className={styles.postcard__img_link}>
                        <SwiperComp className={styles.postcard__img} images={images} />
                    </div>
                    <div className={styles.postcard__text}>
                        <h1 className={`${styles.postcard__title} ${styles.blue}`}>
                            {title}&nbsp;{year}
                        </h1>
                        {text && <li className={styles.postcard__preview_txt}>
                            {text}
                        </li>}
                        <ul className={styles.postcard__preview_ul}>
                            {list.map((item, idx) => {
                                return <li className={styles.postcard__preview_li} key={idx}>
                                    {item}
                                </li>
                            })}
                        </ul>
                        <ul className={styles.postcard__tagbox}>
                            {links && <Links links={links} />}
                        </ul>
                    </div>
                </div>
            </div>
        </section>
      </li>
    );
}

export default CardComp;
